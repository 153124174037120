import { Parameters } from './../../models/parameters';
import { Li } from './../../models/li';
import { Calculation } from './../../models/calculation';
import { Client } from './../../models/client';
import { Injectable } from '@angular/core';
import { Cost } from '../../models/cost';
import { AnnualData } from '../../models/annual.data';

@Injectable()
export class DataService {

    public static client: Client = new Client();
    public static cost: Cost = new Cost();
    public static calculation: Calculation = new Calculation();
    public static liArray: Li[] = [];
    public static parameters: Parameters;
    public static dateTarifaire: String = new Parameters().dateTarifaire;

    public static setup(loadedParameters?: Parameters): void {
        if (!DataService.parameters) {
            if (loadedParameters !== undefined) {
                DataService.setParameters(loadedParameters);
            } else {
                DataService.setParameters(new Parameters());
            }
        }
        console.log('Parameters : ', DataService.parameters);
    }

    private static setParameters(newParameters: Parameters) {
        DataService.parameters = newParameters;
        DataService.dateTarifaire = DataService.parameters.dateTarifaire;
    }

    /**
     * Implementation of NPV function (VAN in EXCEL)
     * Based on https://github.com/govardhan-srinivas/excel-npv
     * @param discountRate
     * @param values
     */
    public static getNetPresentValue(discountRate: number, values: number[]): number {
        let npv = 0;
        const d = discountRate;
        for (let i = 0; i < values.length; i++) {
            npv += values[i] / Math.pow(1 + d, i + 1);
        }
        return Math.round(npv * 10000000) / 10000000;
    }

    public static getFirstACHRecipeTakenIntoAccount(currentAnnualData: AnnualData): number {
        // =SI(COLONNE()-2>$C11;0;(C8*(C4+C5*E5)+C6)+((I4+I6*C5)*10*'Indus - capa pallier - 2018'!M13)/1000)
        // =SI(COLONNE()-2>$C10;0;C7*(C4+C5*E5)+C6)+((I4+I6*C5)*10*'Remise Dév Indus - Tarif 2018'!I14)/1000
        // =SI(COLONNE()-2>$C10;0;C7*(C4+C5*E5)+C6)+((I4+I6*C5)*const10*'Remise Dév Indus - Tarif 2018'!I14)/1000
        // =SI(COLONNE()-2>$C10;0;C7*(C4+C5*E5)+C6)+(calculIntermediaireTCLTCRR*const10*'Remise Dév Indus - Tarif 2018'!I14)/1000
        // =SI(COLONNE()-2>$C10;0;C7*(C4+C5*E5)+C6)+calculIntermediateSecondPart
        // =SI(COLONNE()-2>cracDuration;0;C7*(C4+C5*E5)+C6)+calculIntermediateSecondPart
        // =SI(COLONNE()-2>cracDuration;0;calculIntermediateFirstPart)+calculIntermediateSecondPart

        // (I4+I6*C5)
        const calculIntermediateTCLTCRR = DataService.parameters.tclIndus + DataService.parameters.tcrr * DataService.client.NTR;
        // (calculIntermediaireTCLTCRR*const10*'Remise Dév Indus - Tarif 2018'!I14)
        const calculIntermediateSecondPart
            = calculIntermediateTCLTCRR * DataService.parameters.const10 * currentAnnualData.additionalHourlyCapacity / 1000;
        // C7*(C4+C5*E5)+C6
        const calculIntermediateFirstPart = (currentAnnualData.getNbGwhByDayInFirmEquivalent()
            * (DataService.parameters.getAverageResultExcludingNtrAndFixedDelivery()
                + DataService.client.NTR * DataService.parameters.tcrr)) + DataService.parameters.constanteCelluleC6;

        return calculIntermediateFirstPart + calculIntermediateSecondPart;
    }

    public static getCurrentACHRecipeTakenIntoAccountBasedOnPreviousRecipe(previousACHRecipe: number): number {
        return Math.round(previousACHRecipe * (1 + DataService.parameters.inflationRate) * 10000000) / 10000000;
    }


    public static updateData() {
        const discountAmountGenerated = DataService.generateDiscountAmount();
        const halfGrossCost = DataService.cost.grossCost / 2;
        let result: number;
        if (discountAmountGenerated < halfGrossCost) {
            result = discountAmountGenerated;
        } else {
            result = halfGrossCost;
        }
        DataService.cost.discountAmount = Math.round(result * 100) / 100;
    }

    public static generateDiscountAmount(): number {
        if (DataService.calculation.isVariable) {
            return DataService.generateDiscountAmountForMultipleCalculation();
        } else {
            return DataService.generateDiscountAmountForSimpleCalculation();
        }
    }

    public static generateDiscountAmountForSimpleCalculation(): number {

        const valuesArray: number[] = [];
        for (let index = 0; index < DataService.calculation.cracDuration; index++) {
            if (index === 0) {
                valuesArray.push(DataService.getFirstACHRecipeTakenIntoAccount(DataService.calculation.simpleAnnualData));
            } else {
                valuesArray.push(DataService.getCurrentACHRecipeTakenIntoAccountBasedOnPreviousRecipe(valuesArray[index - 1]));
            }
        }

        return DataService.getNetPresentValue(DataService.parameters.remunerationRate, valuesArray);
    }

    public static generateDiscountAmountForMultipleCalculation(): number {
        const valuesArray: number[] = [];
        for (let index = 0; index < DataService.calculation.cracDuration; index++) {
            const currentAnnualData = DataService.calculation.variableAnnualDataArray[index];
            const recipe = DataService.getFirstACHRecipeTakenIntoAccount(currentAnnualData);
            valuesArray.push(recipe * Math.pow(1 + DataService.parameters.inflationRate, index));
        }


        return DataService.getNetPresentValue(DataService.parameters.remunerationRate, valuesArray);
    }
}
