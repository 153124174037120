import { ParameterService } from './../services/parameter.service';
import { SimulatorPageComponent } from './../pages/simulator-page/simulator-page';
import { HomePageComponent } from './../pages/home-page/home-page';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { OverviewPageComponent } from '../pages/overview-page/overview-page';
import { DataService } from '../services/data/data.service';
import { LiService } from '../services/li.service';
import { HttpModule } from '@angular/http';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';

const appRoutes: Routes = [
    { path: '', component: HomePageComponent },
    { path: 'simulateur', component: SimulatorPageComponent },
    { path: 'overview', component: OverviewPageComponent },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        SimulatorPageComponent,
        OverviewPageComponent,
    ],
    imports: [
        NgbModule.forRoot(),
        RouterModule.forRoot(
            appRoutes,
            {
                enableTracing: false, // <-- debugging purposes only
                useHash: true
            }
        ),
        FormsModule,
        BrowserModule,
        NouisliderModule,
        HttpModule,
        NguiAutoCompleteModule,
    ],
    providers: [
        DataService,
        LiService,
        ParameterService
    ],
    bootstrap: [
        AppComponent,
    ]
})

export class AppModule { }

export class NgbdDropdownBasic { }

export class NgbdTabsetBasic { }
