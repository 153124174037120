import { ParameterService } from './../../services/parameter.service';
import { LiService } from './../../services/li.service';
import { Calculation } from './../../models/calculation';
import { DataService } from './../../services/data/data.service';
import { Component } from '@angular/core';
import { NouiFormatter } from 'ng2-nouislider';
import { Cost } from '../../models/cost';
import { Client } from '../../models/client';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { YearFormatter } from '../../utils/year-formatter';
import { Li } from '../../models/li';

@Component({
    selector: 'app-simulator-page',
    templateUrl: './simulator-page.html',
    styleUrls: ['./simulator-page.scss']
})

export class SimulatorPageComponent {

    public cost: Cost = DataService.cost;
    public client: Client = DataService.client;
    public calculation: Calculation = DataService.calculation;

    public nameClientMessage = '';
    public liArray: Li[] = [];
    public selectedLi: Li;

    public autoCompleteOptions;

    // CapacityTab
    public capacityTabId;
    public tabSimpleCapacity = 'tab-simple-capacity';
    public tabMultipleCapacity = 'tab-multiple-capacity';

    // Dropdown selected
    // customerPrices: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    customerPrices: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    selectedCustomerPrice;

    contactmodal = false;
    // Years range
    ageInd = [3, 4, 5, 6, 7, 8, 9, 10];
    public yearIndex = this.ageInd[0];
    Arr = Array;
    public rangeYear: number[] = [this.calculation.cracDuration];
    public rangeYearConfig: any = {
        min: this.ageInd[0],
        max: this.ageInd[this.ageInd.length - 1],
        step: 1,
        range: {
            min: this.ageInd[0],
            max: this.ageInd[this.ageInd.length - 1]
        },
        pips: {
            mode: 'count',
            values: this.ageInd.length
        },
        tooltips: [new YearFormatter]
    };

    public dateTarifaire: String = '';
    public currentDate: Date = new Date();

    constructor(
        public liService: LiService,
        public parameterService: ParameterService,
    ) {
        this.setup();
    }

    public myValueFormatter(currentLi: Li): string {
        return currentLi.operationalCode;
    }

    public myListFormatter(currentLi: Li): string {
        return currentLi.operationalCode;
    }

    private async setup() {
        if (DataService.parameters === undefined) {
            DataService.setup(await this.parameterService.getParameters());
        }
        this.selectedCustomerPrice = this.client.NTR;
        this.liArray = await this.liService.getLiArray();
        if (this.client.liOperationalCode !== undefined) {
            this.selectedLi = new Li();
            this.selectedLi.operationalCode = this.client.liOperationalCode;
        }
        if (DataService.calculation.isVariable) {
            this.capacityTabId = this.tabMultipleCapacity;
        } else {
            this.capacityTabId = this.tabSimpleCapacity;
        }
        this.searchNameClient(true);
        this.callUpdateData();
        this.dateTarifaire = DataService.dateTarifaire;
    }

    saveRange(index) {
        this.yearIndex = index;
        this.calculation.cracDuration = index;
        this.callUpdateData();
    }

    public callUpdateData() {
        DataService.updateData();
    }

    ChangeCustomerPrice(newCustomerPrice: number) {
        this.selectedCustomerPrice = newCustomerPrice;
        this.client.NTR = newCustomerPrice;
        this.callUpdateData();
    }

    setradio(e: string): void {
        if ('grtCustomerYes' === e) {
            this.client.isGrtClient = true;
        } else {
            this.client.isGrtClient = false;
            this.client.name = '';
            this.client.liOperationalCode = '';
            this.client.NTR = this.selectedCustomerPrice;
            // this.selectedLi = new Li();
        }
    }

    public searchNameClient(firstSearch = false) {
        let searchText: string;
        if (this.selectedLi && this.selectedLi.operationalCode) {
            searchText = this.selectedLi.operationalCode.toUpperCase();
        } else {
            searchText = '';
        }

        const results = this.liArray.filter((currentLi) => {
            return currentLi.operationalCode.startsWith(searchText);
        });

        if (results.length === 0) {
            this.nameClientMessage = 'Aucun client trouvé';
        } else if (results.length === 1) {
            this.client.name = results[0].name;
            this.client.liOperationalCode = results[0].operationalCode;
            this.nameClientMessage = this.client.name;
            this.ChangeCustomerPrice(results[0].ntr);
        } else if (results.length > 1) {
            this.client.name = '';
            this.client.liOperationalCode = '';
            if (firstSearch) {
                this.nameClientMessage = '';
            } else {
                this.nameClientMessage = 'Veuillez préciser le LI';
            }
        }

        return results.map(li => li.operationalCode + ' - ' + li.name);
    }

    public beforeChangeCapacitiesTab($event: NgbTabChangeEvent) {
        if ($event.nextId === this.tabMultipleCapacity) {
            this.calculation.isVariable = true;
            DataService.updateData();
        } else {
            this.calculation.isVariable = false;
            DataService.updateData();
        }
    }
}
