import { ParameterService } from './../services/parameter.service';
import { LiService } from './../services/li.service';
import { DataService } from './../services/data/data.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    constructor() { }
}
