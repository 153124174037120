
import { Injectable } from '@angular/core';

@Injectable()
export class Parameters {

    // taux d'inflation
    public inflationRate = 0.01;
    // Taux de rémunération et d'actualisation pour la remise développement
    public remunerationRate = 0.0525;
    // Taux appliqué pour calculer la recette raccordement annuelle
    public annualConnectionRevenueCalculateRate = 0.1020;

    // TCSP
    public tcsp = 91.78;
    // TCL Indus
    public tclIndus = 33.20;
    // TCRR
    public tcrr = 83.43;

    // Cellule C6 utilisée dans le calcul mais vide dans le document.
    public constanteCelluleC6 = 0;

    // Constante utilisé dans le calcul de la recette de prise en compte à la première année
    public const10 = 10;

    // Date d'application tarifaire
    public dateTarifaire = '1 Avril 2020';

    // CA moyen aval (k€/GWh/j) hors NTR et livraison fixe
    public getAverageResultExcludingNtrAndFixedDelivery() {
        return this.tcsp + this.tclIndus;
    }


}
