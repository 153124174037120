import { Router } from '@angular/router';

// core
import { Component } from '@angular/core';
import { Cost } from '../../models/cost';
import { DataService } from '../../services/data/data.service';
import { Client } from '../../models/client';
import { Calculation } from '../../models/calculation';

@Component({
    selector: 'app-overview-page',
    templateUrl: 'overview-page.html',
    styleUrls: ['./overview-page.scss']
})

export class OverviewPageComponent {

    public cost: Cost = DataService.cost;
    public client: Client = DataService.client;
    public calculation: Calculation = DataService.calculation;

    public dateFormat: String = 'DD/MM/YYYY';

    public title = 'Aperçu';
    public errors: { [id: string]: string } = {};

    public canShow = false;

    public callBack: Function;
    public callBackButtonTitle = '';

    public currentDate: Date = new Date();
    public dateTarifaire: String = DataService.dateTarifaire;

    constructor(
        public router: Router,
    ) {
        this.setup();
        if (this.calculation === undefined) {
            this.router.navigateByUrl('/simulateur');
        }
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        // générer le document, ouvrir l'interface d'impression et revenir au simulateur
        setTimeout(() => {
            window.print();
            setTimeout(() => { this.router.navigateByUrl('/simulateur'); }, 100);
        }, 100);
    }

    public getClientName() {
        if (this.client) {
            let result = this.client.liOperationalCode ? this.client.liOperationalCode : '';
            const name = this.client.name ? this.client.name : '';

            if (result.length > 0 && name.length > 0) {
                result = result + ' - ' + name;
            } else {
                if (name.length > 0) {
                    result = name;
                }
            }

            return result;

        } else {
            return '';
        }
    }

    protected async setup() {
        this.canShow = true;
    }
}
