import { Li } from './../models/li';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LiService {

    public liArray: Li[];

    constructor(protected http: Http) { }

    public getLiArray(): Promise<Li[]> {
        if (this.liArray !== undefined) {
            return Promise.resolve(this.liArray);
        } else {
            return this.http.get('assets/li.json')
                .toPromise()
                .then((response: any) => {
                    const data = JSON.parse(response._body).Feuil1;
                    const newLiArray: Li[] = [];

                    data.forEach((element) => {
                        const currentLi: Li = new Li();
                        currentLi.mail = element['mail'];
                        currentLi.operationalCode = element['CODE OPERATIONNEL'];
                        currentLi.name = element['LISTE DES CONSOMMATEURS RACCORDES AU RESEAU DE TRANSPORT DE GRTgaz'];
                        currentLi.ntr = element['NTR'];
                        currentLi.tel = element['téléphone'];
                        newLiArray.push(currentLi);
                    });

                    this.liArray = newLiArray;
                    return this.liArray;
                });
        }
    }
}
