
import { Injectable } from '@angular/core';

@Injectable()
export class Li {

    public operationalCode: string;
    public name: string;
    public ntr: number;
    public mail: string;
    public tel: string;

}
