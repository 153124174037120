import { Parameters } from './../models/parameters';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ParameterService {

    public parameters: Parameters;

    constructor(protected http: Http) { }

    public getParameters(): Promise<Parameters> {
        if (this.parameters !== undefined) {
            return Promise.resolve(this.parameters);
        } else {
            return this.http.get('assets/parameters.json')
                .toPromise()
                .then((response: any) => {

                    try {
                        const data = JSON.parse(response._body).Feuil1[0];
                        const currentParameter: Parameters = new Parameters();
                        // // Taux d'inflation
                        // inflationRate = 0.01;
                        // // Taux de rémunération et d'actualisation pour la remise développement
                        // remunerationRate = 0.0525;
                        // // Taux appliqué pour calculer la recette raccordement annuelle
                        // annualConnectionRevenueCalculateRate = 0.1020;
                        // // TCSP
                        // tcsp = 90.33;
                        // // TCL Indus
                        // tclIndus = 31;
                        // // TCRR
                        // public tcrr = 77.91;
                        // // Cellule C6 utilisée dans le calcul mais vide dans le document.
                        // public constanteCelluleC6 = 0;
                        // // Constante utilisé dans le calcul de la recette de prise en compte à la première année
                        // public const10 = 10;

                        currentParameter.inflationRate = Number.parseFloat(data['Taux d\'inflation']);
                        currentParameter.remunerationRate
                            = Number.parseFloat(data['Taux de rémunération et d\'actualisation pour la remise développement']);
                        currentParameter.annualConnectionRevenueCalculateRate
                            = Number.parseFloat(data['Taux appliqué pour calculer la recette raccordement annuelle']);
                        currentParameter.tcsp = Number.parseFloat(data['TCSP']);
                        currentParameter.tclIndus = Number.parseFloat(data['TCL Indus']);
                        currentParameter.tcrr = Number.parseFloat(data['TCRR']);
                        currentParameter.constanteCelluleC6
                            = Number.parseFloat(data['Cellule C6 utilisée dans le calcul mais vide dans le document']);
                        currentParameter.const10 = Number.parseFloat(
                            data['Constante utilisée dans le calcul de la recette de prise en compte à la première année']);
                        currentParameter.dateTarifaire = data['Date tarifaire'];

                        this.parameters = currentParameter;
                    } catch (error) {
                        console.log('error in parsing parameters.json', error);
                        this.parameters = new Parameters();
                    }
                    return this.parameters;
                });
        }
    }
}
