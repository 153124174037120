import { Component } from '@angular/core';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.html',
    styleUrls: ['./home-page.scss']
})

export class HomePageComponent {
    public currentDate: Date = new Date();
}
