
import { Injectable } from '@angular/core';

@Injectable()
export class Cost {

    private _postCost = 0;
    private _connectionCost = 0;
    private _grossCost = 0;
    private _discountAmount = 0;
    private _finalCost = 0;

    public get postCost(): number {
        return this._postCost;
    }

    public get connectionCost(): number {
        return this._connectionCost;
    }

    public get grossCost(): number {
        return this._grossCost;
    }

    public get discountAmount(): number {
        return this._discountAmount;
    }

    public get finalCost(): number {
        return this._finalCost;
    }

    public set postCost(value: number) {
        this._postCost = value;
        this.updateGrossCost();
    }

    public set connectionCost(value: number) {
        this._connectionCost = value;
        this.updateGrossCost();
    }

    public set grossCost(value: number) {
        this._grossCost = value;
        this.updatefinalCost();
    }

    public set discountAmount(value: number) {
        this._discountAmount = value;
        this.updatefinalCost();
    }

    public set finalCost(value: number) {
        this._finalCost = value;
    }

    public updateGrossCost(): void {
        this.grossCost = (this._postCost ? this._postCost : 0) + (this._connectionCost ? this._connectionCost : 0);
    }

    public discountPercent(): number {
        let discountPercent = 0;
        if (this.grossCost && this.grossCost !== 0) {
            discountPercent = Math.round(100 * this.discountAmount / this.grossCost);
        }
        return discountPercent;
    }

    private updatefinalCost() {
        const result = (this._grossCost ? this._grossCost : 0) - (this.discountAmount ? this.discountAmount : 0);
        this.finalCost = Math.round(100 * result) / 100;
    }

}
