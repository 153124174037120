
import { Injectable } from '@angular/core';

@Injectable()
export class Client {

    public name: string = undefined;
    public liOperationalCode: string = undefined;

    private _isGrtClient = false;
    private _isGrtClientString = 'false';
    public NTR = 0;

    public get isGrtClient(): boolean {
        return this._isGrtClient;
    }

    public get isGrtClientString(): string {
        return this._isGrtClientString;
    }

    public set isGrtClientString(value: string) {
        console.log('isGrtClientString', value);

        this._isGrtClientString = value;
        this._isGrtClient = this._isGrtClientString === 'true' ? true : false;
    }

    public set isGrtClient(value: boolean) {
        this._isGrtClient = value;
        this._isGrtClientString = (this._isGrtClient && this._isGrtClient === true) ? 'true' : 'false';
    }
}
