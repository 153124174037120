import { DataService } from './../services/data/data.service';
import { AnnualData } from './annual.data';

import { Injectable } from '@angular/core';

@Injectable()
export class Calculation {

    private _isVariable = false;
    private _cracDuration = 3;

    public simpleAnnualData = new AnnualData();

    public variableAnnualDataArray = [new AnnualData(), new AnnualData(), new AnnualData(), new AnnualData(), new AnnualData()
        , new AnnualData(), new AnnualData(), new AnnualData(), new AnnualData(), new AnnualData()];


    constructor() {
        this.updateVariableAnnualDataArray();
    }

    public get isVariable(): boolean {
        return this._isVariable;
    }

    public set isVariable(value: boolean) {
        this._isVariable = value;
    }

    public get cracDuration(): number {
        return this._cracDuration;
    }

    public set cracDuration(value: number) {
        this._cracDuration = value;
        this.updateVariableAnnualDataArray();
    }

    public getActivatedVariableAnnualDataArray(): AnnualData[] {
        return this.variableAnnualDataArray.filter((value) => value.activated);
    }


    private updateVariableAnnualDataArray(): void {
        for (let index = 0; index < this.variableAnnualDataArray.length; index++) {
            const element = this.variableAnnualDataArray[index];
            if (index + 1 <= this.cracDuration) {
                element.activated = true;
            } else {
                element.activated = false;
            }
        }
    }
}
