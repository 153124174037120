
import { Injectable } from '@angular/core';

@Injectable()
export class AnnualData {

    public firmDailyCapacity = 0;
    public interruptibleDailyCapacity = 0;
    public additionalHourlyCapacity = 0;

    public activated = true;

    // nb GWH/J pour l'entreprise en équivalent ferme
    public getNbGwhByDayInFirmEquivalent() {
        return (this.firmDailyCapacity + 0.5 * this.interruptibleDailyCapacity) / 1000;
    }

}
