import { NouiFormatter } from 'ng2-nouislider';

export class YearFormatter implements NouiFormatter {
    to(value: number): string {
        const output = value + ' <span>ans</span>';
        return output;
    }

    from(value: string): number {
        return Number(value.split(' ')[0]);
    }
}
